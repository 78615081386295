@import url('https://fonts.googleapis.com/css2?family=PT+Serif:ital,wght@0,400;0,700;1,400;1,700&display=swap');

html {
  scroll-behavior: smooth;
}

/* Offset smoothscroll anchor found here: https://spigotdesign.com/smooth-scroll-offset-anchor-links-with-css/#smooth-scroll */
:target:before {
  content: "";
  display:block;
  height: 150px;
  margin-top: -150px;
}

::-webkit-scrollbar {
  width:5px;
  z-index:1;
}

::-webkit-scrollbar-track {
  background: rgba(0,0,0,0);
}

::-webkit-scrollbar-track:hover {
  background: rgba(40,40,40)
}

::-webkit-scrollbar-thumb {
  background: rgba(60,60,60,.5);
}

::-webkit-scrollbar-thumb:hover {
  background: rgba(80,80,80,.8)
}

::-webkit-scrollbar-track-piece {
  width:none;
}

body {
  background-color:rgb(20,20,20);
  min-height:100%;
}

.container {
  background-color:rgb(20,20,20);
  color:whitesmoke;
  margin-top:150px;
}

#main-title {
  font-family: 'PT Serif', serif;
  font-size:34px;
  margin:0;
}

.header-row {
  position:fixed;
  top:0;
  left:0;
  width:100%;
  background-color:black;
  z-index:1;
}

#header {
  display:flex;
  flex-flow: row nowrap;
  justify-content: space-around;
  align-items: center;
  gap:10px;
  padding:10px;
}
.nav-button {
  min-width:60px;
  width:60px;
  min-height:60px;
  height:60px;
  display:grid;
  place-items:center;
  font-size:32px;
  border: 1px solid grey;
  transition: .2s all ease-out;
}

.nav-button:hover {
  cursor:pointer;
  box-shadow:3px 3px grey;
}

#menu-modal {
  position:absolute;
  z-index:1;
  height:100vh;
  width:100%;
  top:0;
  left:0;  
  background-color:rgba(105,105,105,.4);
}

#menu {
  position:absolute;
  max-width:70%;
  width:300px;
  background-color:black;
  border:1px solid white;
  z-index:2;
  top:0;
  right:0;
  padding: 10px;
  display:grid;
  grid-template-columns: 2fr 1fr;
  grid-template-rows: 60px auto;
  grid-template-areas: "title close"
                      "nav nav";
}

#menu-title {
  grid-area: title;
  font-family: 'PT Serif', serif;
  border-bottom:1px solid white;
  padding:.25em;
  margin-bottom:0;
}

#menu-close{
  grid-area: close;
  border:none;
  justify-self: end;
}

#menu-list {
  grid-area: nav;
  list-style:none;
  padding:0;
  margin:0;
}
.menu-item{
  text-align:center;
  color:whitesmoke;
  font-weight:bold;
  
  padding:1em 0px;
  transition: .2s all ease-out;
}

.menu-item:hover {
  box-shadow: 2px 2px grey;
}

.menu-link, #top-link, .small-link {
  color:whitesmoke;
  text-decoration: none;
}

.hero-text {
  font-family: 'PT Serif', serif;
  font-style:italic;
  text-align:center;
  font-size:36px;
  background-color:rgba(0,0,0,.8);
  padding: 1em;
  text-shadow: 4px 4px black;
  
}

.section-title {
  font-size:28px;
  font-family: 'PT Serif', serif;
  font-weight:bold;
  border-bottom:1px solid white;
}

.img-background {
  min-height:300px;
  background-color:rgba(255,255,255,.2);
  background-image: url("../public/microphone.jpg");
  background-repeat: no-repeat;
  background-position: center; 
  background-size:cover;
  display:grid;
  place-items: center;
  margin-bottom:15px;
  filter:grayscale(100%);
}

p, .bullet-points {
  font-size:18px;
}

.bio-container {
  min-height:250px;   
}

#headshot {
  width:50%;
  max-width:250px;
  border-radius:50%;
  float:left;
  padding-right:10px;
}

input, textarea {
  width:100%;
  display:block;
  color:black;
}

.submit {
  display:block;
  text-align:right;
}

#blog-posts-container {
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content:flex-start;
  align-content:center;
  gap:1em;
  overflow: auto;
  margin-bottom: 20px;
}

.blog-card {
  border:1px solid white;
  padding:10px;
  flex-basis:200px;
  min-width:150px;
}

.blog-card:hover {
  cursor:pointer;
  box-shadow: 3px 3px grey;
}

.blog-card-title {
  margin-bottom:5px;
  text-decoration:underline;
}

.blog-card-body {
  max-height:6em;
  overflow: hidden;
  white-space: wrap;
  text-overflow: ellipsis;
}

.footer-info {
  display:flex;
  flex-flow:column nowrap;
  gap:1px;
}

.footer-info > * {
  padding:20px;
}

.separator {
  border-top:1px solid grey;
}

.error {
  color: red;
  text-align:center;
  border: 1px solid red;
}

.small {
  font-size:12px;
}

#privacy-button:hover {
  cursor:pointer;
  text-decoration:underline;
}

#blog-modal{
  position:fixed;
  top:0;
  left:0;
  width:100%;
  height:100vh;
  background-color:rgb(60,60,60);
  z-index:2;
}

#blog-container {
  padding:5%;
  scroll-behavior: auto;
  overflow: scroll;
  height:98vh;
  max-height:98vh;
}

#new-blog-button {
  display:block;
}

#list-of-posts {
  border:2px solid grey;
}

.blog-body-input {
  height:60vh;
}

.checkbox-area {
  display:flex;
  flex-flow:row nowrap;
  justify-content:space-between;
  align-content:center;
}

.checkbox-area > * {
  margin:0;
  padding:1em;
  align-self:center;
}

#viewer-modal{
  position:fixed;
  top:0;
  left:0;
  width:100%;
  height:100vh;
  background-color:rgba(60,60,60, .4);
  z-index:2;
}

#blog-viewer {
  position:fixed;
  z-index:3;
  top:0;
  left:0;
  background-color: rgb(40,40,40);
  display:grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 80vh 1fr;
  grid-template-areas:
    "blog-title"
    "blog-body"
    "viewer-close";
  justify-content:space-between;
  align-content: center;
  gap:1em;
  height: 100vh;
  width:100%;
}

#blog-viewer > * {
  margin: 0 10px;
}

#blog-viewer > h2 {
  grid-area: blog-title;
}

#blog-viewer > p {
  grid-area: blog-body;
  overflow: auto;
  white-space:pre-wrap;
  border:1px solid white;
  padding:10px;
}

#blog-viewer > button {
  grid-area: viewer-close;
}

.post-card {
  display:grid;
  grid-template-columns: 3fr 1fr;
  grid-template-rows:1fr 1fr;
  grid-template-areas:
    "title delete"
    "body delete";
  overflow:hidden;
  align-items: center;
  padding: 10px;
  border: 1px solid rgba(115,115,115,.4);
  transition: all .2s ease;
}

.post-card:hover {
  cursor: pointer;
  border: 1px solid rgba(215,215,215,1);
}

.blog-title-preview {
  grid-area:title;
}

.blog-body-preview {
  grid-area:body;
  max-height:1.5em;
  overflow:hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width:100%;
  padding-right:10px;
}

.delete-post, .delete-confirm {
  grid-area:delete;
  height:48px;
  width:48px;
  color:red;
  border: 1px solid red;
  margin-right: 10px;
  justify-self: flex-end;
  display:grid;
  place-items:center;
  transition: all .25s ease;
}



.delete-post:hover, .delete-confirm:hover {
  background-color:red;
  color:white;
  box-shadow:3px 3px pink;
  cursor:pointer;
}

.delete-post > * {
  font-size:16pt;
}

.delete-post-card {
  padding:10px;
  display:grid;
  grid-template-columns: 100%;
  grid-template-rows: 1fr 1fr 1fr;
  grid-template-areas:
    "prompt"
    "delete-button"
    "cancel-button";
  gap:1em;
  place-items: center;
}

.delete-post-card > * {
  margin: 0;
}

.delete-post-card > p {
  grid-area:prompt;
}

.delete-confirm {
  grid-area:delete-button;
  height: auto;
  width: auto;
  place-self:center;
}

.cancel-delete {
  grid-area:cancel-button;
}

footer {
  z-index: -1;
}

/* Larger than mobile */
@media (min-width: 400px) {
  /* Offset smoothscroll anchor found here: https://spigotdesign.com/smooth-scroll-offset-anchor-links-with-css/#smooth-scroll */
  :target:before {
    content: "";
    display:block;
    height: 105px;
    margin-top: -105px;
  }

}

/* Larger than phablet */
@media (min-width: 550px) {
  /* Offset smoothscroll anchor found here: https://spigotdesign.com/smooth-scroll-offset-anchor-links-with-css/#smooth-scroll */
  :target:before {
    content: "";
    display:block;
    height: 120px;
    margin-top: -120px;
  }

  #main-title {
    font-size:42px;
  }

  input {
    max-width: 450px;
  }

  .container {
    margin-top:120px;
  }

  .delete-post-card {
    display:grid;
    grid-template-columns: 1fr 40% 40% 1fr;
    grid-template-rows: 1fr 1fr;
    grid-template-areas:
      ". prompt prompt ."
      ". delete-button cancel-button .";
    gap:1em;
    place-items:center;
  }
}

/* Larger than tablet */
@media (min-width: 750px) {
  /* Offset smoothscroll anchor found here: https://spigotdesign.com/smooth-scroll-offset-anchor-links-with-css/#smooth-scroll */
  :target:before {
    content: "";
    display:block;
    height: 135px;
    margin-top: -135px;
  }

  #main-title {
    font-size:48px;
  }
  
  .container {
    margin-top:110px;
  }

  .footer-info {
    display:flex;
    flex-flow:row nowrap;
    gap:1px;
    margin-bottom:10px;
  }

  .footer-info > * {
    flex-basis: 50%;
  }

  .separator {
    border-top:none;
    border-left:1px solid grey;
  }

}

/* Larger than desktop */
@media (min-width: 1000px) {
  /* Offset smoothscroll anchor found here: https://spigotdesign.com/smooth-scroll-offset-anchor-links-with-css/#smooth-scroll */
  :target:before {
    content: "";
    display:block;
    height: 85px;
    margin-top: -85px;
  }
}

/* Larger than Desktop HD */
@media (min-width: 1200px) {
  #blog-viewer {
    width:80%;
    max-width:1100px;
    left:auto;
    place-self:center;
  }

}